<template>
  <page :loading="loading">
    <template v-slot:left>
      <v-img
        v-if="logo"
        alt="Partner Logo"
        class="shrink mb-2"
        contain
        :src="logo"
        transition="scale-transition"
        height="200"
      />
      <v-img
        v-else
        alt="WithU Logo"
        class="shrink mb-2"
        contain
        src="@/assets/withu-logo.png"
        transition="scale-transition"
        height="60"
      />
    </template>
    <template v-slot:right>
      <div>
        <div class="white--text text-h4 font-weight-bold">
          Purchase Cancelled
        </div>
        <div class="lightGrey--text text-title font-weight-bold">
          Looks like your purchase was cancelled. Want to try again?
        </div>
        <div class="mt-10">
          <v-btn
            color="secondary"
            rounded
            dark
            class="black--text font-weight-bold mx-1 px-10"
            @click="subscribe"
          >
            Try again
          </v-btn>
        </div>
      </div>
    </template>
  </page>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SubscriptionCancelled",
  data() {
    return {};
  },
  watch: {},

  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    loading() {
      return this.account.loading;
    },
    deal() {
      return this.account.redeem.deal;
    },
    logo() {
      if (this.deal && this.deal.web && this.deal.web.logo) {
        return this.deal.web.logo;
      } else {
        return null;
      }
    },
  },
  methods: {
    ...mapActions({
      checkoutSession: "account/checkoutSession",
      setLoading: "account/setLoading",
    }),
    subscribe() {
      this.$router.push("/subscribe");
    },
  },
  beforeMount() {
    this.setLoading(false);
  },
};
</script>

<style scoped>
</style>
